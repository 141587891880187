import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/mdx-layout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`This Privacy Policy applies to Drive Revenue’s Websites and Applications and its related offerings.`}</p>
    <p>{`It is Drive Revenue’s policy to respect your privacy regarding any information we may collect from you while utilising our Websites and/or Applications. Drive Revenue’s fundamental principles in respect of your privacy are as follows:`}</p>
    <p>{`Drive Revenue will not ask you for personal information unless it is required in terms of your use of the Websites and/or Applications;`}</p>
    <p>{`Drive Revenue will not share your personal information with anyone except to comply with the law, develop our products or to protect your rights or that of another user; and`}</p>
    <p>{`Drive Revenue does not store personal information on its servers unless required by the on-going operation of its Websites and/or Applications.`}</p>
    <p>{`Drive Revenue’s policy, which incorporates the aforesaid principles are as follows:`}</p>
    <h2>{`1.DATA STORAGE`}</h2>
    <p>{`Drive Revenue uses third-party vendors and hosting partners to provide the necessary hardware, software, networking, storage, and related technology required to run Drive Revenue and Drive Revenue’s Applications. Drive Revenue owns the code and all rights to the Drive Revenue Websites and Applications, but you retain all rights to your data.`}</p>
    <h2>{`2.ONLINE SERVICE SUBSCRIBERS / WEBSITE VISITORS`}</h2>
    <p>{`2.1. Drive Revenue collects non-personally-identifiable information of the sort typically made available by web browsers and servers, such as, but not limited to, browser type, language preference, referring site, and date and time of each visit, to better understand how visitors use its Websites and Applications. This information may be released in the aggregate, e.g. a report on the trends in usage of the Websites and Applications.`}</p>
    <p>{`2.2. Drive Revenue also collects potentially personally identifying information including, but not limited to, Internet Protocol (IP) addresses. Drive Revenue will only use and disclose this information under the same circumstances as those applicable in respect of personally identifying information, as described below.`}</p>
    <h2>{`3.PERSONALLY-IDENTIFYING INFORMATION`}</h2>
    <p>{`3.1. When you register for an Account with an Application(s), we require information including, but not limited to, your name, contact details and payment information. Drive Revenue uses this collected information for product and service provision, billing, identification and authentication, service improvement, communications and internal research.`}</p>
    <p>{`3.2. Drive Revenue discloses potentially personally identifying information and personally identifying information only to those of its employees, contractors and affiliated organizations that need to know such information in order to process it on behalf of Drive Revenue or to provide services available at Drive Revenue’s Websites or on its Applications.`}</p>
    <p>{`3.3. Some of these employees, contractors and affiliated organizations may be situated outside of your home country. By using Drive Revenue’s Websites and/or Applications you consent to the possible transfer and storage of such information outside of your home country for the purposes outlined above, and therefore you accept that such transfer is not in violation of any laws.`}</p>
    <p>{`3.4. Drive Revenue will not rent nor sell potentially personally identifiable information or personally identifiable information to anyone, except as provided for in terms of clause 6 hereof.`}</p>
    <p>{`3.5. Other than as described above, Drive Revenue will only disclose potentially personally identifiable information or personally identifiable information in response to a subpoena, court order, governmental request or legislative procedure, or when Drive Revenue believes in good faith that disclosure is reasonably necessary to protect the property or rights of Drive Revenue, third parties or the public at large. Such disclosure will be made by Drive Revenue following notification to the primary email address specified in your Account.`}</p>
    <p>{`3.6. If you are a registered user of Drive Revenue’s Applications and have supplied your email address and other contact information, Drive Revenue may, subject to your contact preferences, occasionally contact you to tell you about new features, solicit feedback or to keep you updated on Drive Revenue and our products.`}</p>
    <p>{`3.7. You may manage your contact preferences and update your contact details under your User profile in the Applications. You can unsubscribe from marketing related communications, but not from communications necessary for use of Drive Revenue’s Applications, for the functioning of the Account you have with Drive Revenue and billing in respect thereof.`}</p>
    <p>{`3.8. Drive Revenue takes all measures reasonably necessary to protect against the unauthorised access, use, alteration or destruction of potentially personally identifiable information or personally identifiable information.`}</p>
    <h2>{`4.PERSONAL IDENTIFIABLE INFORMATION OF CHILDREN`}</h2>
    <p>{`Drive Revenue does not intentionally collect personal identifiable information from anyone who is under 13 years of age. If it is discovered that Drive Revenue has unintentionally collected personally identifiable information from such a person Drive Revenue will delete that information immediately.`}</p>
    <h2>{`5.COOKIES & SIMILAR TECHNOLOGIES`}</h2>
    <p>{`Drive Revenue may use cookies and similar technologies to help identify and track visitors, their usage of Drive Revenue’s Websites and Applications, and their access preferences. Cookies are required for the correct operation of the Applications, but are optional for use of the Websites.`}</p>
    <h2>{`6.BUSINESS TRANSFERS`}</h2>
    <p>{`In the event that Drive Revenue or its business is acquired, User information will be acquired by such third party. You acknowledge that such transfer of your User information may occur, and that any acquirer of Drive Revenue may continue to use your personal information in accordance with the terms of this policy in force at the time.`}</p>
    <h2>{`7.PRIVACY POLICY CHANGES`}</h2>
    <p>{`Drive Revenue may change its Privacy Policy from time to time and subsequently you should check Drive Revenue’s Privacy Policy regularly, as your continued use of the Websites and Applications after such a change will constitute your acceptance of such changes. We will notify you about any significant changes in the way we deal with your potentially personally identifying information and your personally identifying information by sending a notice to the primary email address specified in your account or by placing a prominent notice on our Websites.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      