import React from "react";
import { MDXProvider } from "@mdx-js/react";

import Layout from "./layout";
import SEO from "./seo";
import { Container, Row, Col } from "reactstrap";

const MdxLayout = ({ children, pageContext }) => {
    const { title } = pageContext.frontmatter;

    return (
        <Layout>
            <SEO title={title} />

            <Container fluid style={{ background: "linear-gradient(to right,#3750C2 ,#151445 100%)", height: "40vh", paddingTop: 100 }}>
                <Row className="h-100">
                    <Col className="d-flex align-items-center justify-content-center text-white">
                        <h1 className="display-3">
                            {title}
                        </h1>
                    </Col>
                </Row>
            </Container>

            <Container className="pt-5 pb-5 mb-5">
                <Row>
                    <Col>
                        <MDXProvider components={{}}>
                            {children}
                        </MDXProvider>
                    </Col>
                </Row>
            </Container>
        </Layout>
    )
}

export default MdxLayout;
